import React from "react";
import { useVideosWithIdsQuery } from "@graphql/generated/graphql";
import { YoutubePlayer } from "./player";
import { Trans } from "@lingui/macro";
import Link from "next/link";
import Image from "next/image";

interface Videos {
  id: string;
  externalId?: string;
  title?: string;
  description?: string;
}

export interface BlockProps {
  videos: Videos[];
}

export const Component: React.FC<BlockProps> = ({ videos }) => {
  const { data, loading, error } = useVideosWithIdsQuery({
    variables: {
      ids: videos.map((video) => video.id),
    },
  });

  return (
    <div className="rich-videos-stack-videos">
      {(loading || error) && (
        <div className="py-64 text-center font-sen text-text-lightest">
          <Trans>Loading...</Trans>
        </div>
      )}
      {data &&
        !error &&
        data.videosWithIds.map((video) => (
          <div key={video.id} className="space-y-4 rounded pb-5 pt-2">
            <YoutubePlayer youtubeId={video.externalId} />
            <p className="font-poppins font-bold text-text-darkest hover:text-text-dark md:text-2xl">
              {video.title}
            </p>
            <div>
              <Link
                href={`/publishers/${video.newsPublisher.slug}?view=videos`}
                passHref
              >
                <a>
                  <div className="flex items-center">
                    <div className="relative h-6 w-6 drop-shadow-custom">
                      <Image
                        className="rounded-lg"
                        src={video.newsPublisher.iconUrl}
                        alt={video.newsPublisher.name}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>

                    <p className="ml-2 font-sen text-sm text-text-dark">
                      {video.newsPublisher.name}
                    </p>
                  </div>
                </a>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

export default {
  name: "video",
  inputNames: ["video"],
  defaultProps: {
    videos: [],
  },
  component: Component,
};
